import { GetStaticProps, NextPage } from "next";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import styles from "./404.module.scss";

import CTAButton from "@components/generic/ctaButton";
import { DEFAULT_LANGUAGE_BACKUP } from "@components/header/langSelector";
import { getRootPath } from "@lib/helper/pathUtils";
import { custom404Images } from "@lib/images";
import { TRANSLATION_FILES } from "@pages/index";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const activeLocale = locale || DEFAULT_LANGUAGE_BACKUP;

  return {
    props: {
      ...(await serverSideTranslations(activeLocale, TRANSLATION_FILES)),
    },
  };
};

const Custom404: NextPage = () => {
  const { t } = useTranslation("custom404");
  const rootPath = getRootPath();

  return (
    <div className={styles["custom404"]}>
      <div className={styles["custom404__logo"]}>
        <Image
          src={custom404Images.logo}
          alt="custom 404 image"
          fill
          sizes="100vw"
          style={{
            objectFit: "cover",
          }}
        />
      </div>
      <h2 className={styles["custom404__title"]}>{t("title")}</h2>
      <p>{t("content")}</p>
      <CTAButton className={styles["custom404__button"]} href={rootPath}>
        {t("buttonText")}
      </CTAButton>
    </div>
  );
};

export default Custom404;
